import React from "react";
import { Link } from "gatsby";

import MainLayout from "../layouts/MainLayout";
import Breadcrumb from "../components/common/breadcrumb";

// import Custom Components
import PageHeader from "../components/common/page-header";
import Accordion from "../components/features/accordion/accordion";
import Card from "../components/features/accordion/card";
import Seo from "../components/common/SEO";

function FAQ() {
  return (
    <MainLayout>
      <Seo title="Owls - FAQ Page" />
      <div className="main">
        <h1 className="d-none">FAQ Page</h1>

        <PageHeader title="F.A.Q" />
        <Breadcrumb title="FAQ" />

        <div className="page-content">
          <div className="container">
            <p>
              Below are answers to commonly asked questions. If you don't see
              what you're looking for, please contact us . Click on the question
              to see the answer.
            </p>

            <Accordion adClass="accordion-rounded">
              <Card
                title="Where is your retail store located?"
                adClass="card-box card-sm bg-dark-theme   "
              >
                Ohio Wholesale Liquidation and Surplus <br />
                1245 Alum Creek Drive, Columbus, OH 43209
              </Card>

              <Card
                title="What are your store hours?"
                adClass="card-box card-sm bg-dark-theme   "
              >
                Sunday: 10am to 4pm <br />
                Monday: 12pm to 6pm
                <br />
                Tuesday: 12pm to 6pm
                <br />
                Wednesday: 10am to 6pm
                <br />
                Thursday: 10am to 6pm
                <br />
                Friday: 10am to 6pm
                <br />
                Saturday: 10am to 4pm
                <br />
                *to make an appointment, call the store: 614-549-6005 or email
                us contact@owls614.com
              </Card>

              <Card
                title="How much is delivery?"
                adClass="card-box card-sm bg-dark-theme   "
              >
                Delivery is only eligible for orders $500 or more and for
                delivery addresses within an hour of our store. Call us directly
                614-549-6005 or email us to set up delivery contact@owls614.com
              </Card>

              <Card
                title="How long does delivery take?"
                adClass="card-box card-sm bg-dark-theme   "
              >
                We deliver within 7 to 10 days from the purchase
              </Card>

              <Card
                title=" What forms of payment do you accept? "
                adClass="card-box card-sm bg-dark-theme   "
              >
                Cash and card (3% fee)
              </Card>
            </Accordion>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <Accordion adClass="accordion-rounded">
                    <Card
                      title="When are your pallet sales? "
                      adClass="card-box card-sm bg-dark-theme  "
                    >
                      Twice a month and on Saturdays. For more details and to
                      receive updates on our pallet sales, email us at
                      contact@owls614.com
                    </Card>
                    <Card
                      title="How do I buy LTL or Truckloads? "
                      adClass="card-box card-sm bg-dark-theme  "
                    >
                      Please call the store or email us for more details <br />
                      614-549-6005 or contact@owls614.com
                    </Card>
                    <Card
                      title="Can I pay for an item and pick it up later?"
                      adClass="card-box card-sm bg-dark-theme  "
                    >
                      Yes, at checkout, you can set up an in-store pick up. You
                      will have 7 days to pick up the item or we will put it
                      back in the store for sale. No refunds. You can also set
                      up a pick up after shopping in our store..
                    </Card>
                    <Card
                      title="Will your staff help me load my items when I shop in the store or schedule a pick up?"
                      adClass="card-box card-sm bg-dark-theme  "
                    >
                      Yes. If you cannot help our staff load your items, we ask
                      that you bring someone to help.
                    </Card>
                    <Card
                      title="What is your return or exchange policy?"
                      adClass="card-box card-sm bg-dark-theme  "
                    >
                      If there is functional damage to your item, you can bring
                      your item back to the store with your receipt within 7
                      days of your purchase. We will then give you a full refund
                      or an exchange. Otherwise, all sales final.
                    </Card>
                    <Card
                      title="Do I need a membership to shop at your retail store?"
                      adClass="card-box card-sm bg-dark-theme   "
                    >
                      No. We are open to the public.
                    </Card>
                    <Card
                      title=" Will you deliver assembled Fitness equipment?"
                      adClass="card-box card-sm bg-dark-theme   "
                    >
                      No. Assembled Fitness equipment is for in-store pick up
                      only.
                    </Card>
                    <Card
                      title="  Do you charge an assembly fee?"
                      adClass="card-box card-sm bg-dark-theme   "
                    >
                      Yes. All assembly starts at a $35 fee. The item + fee must
                      be paid in advance.
                    </Card>{" "}
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="cta cta-display bg-dark  pt-4 pb-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10 col-lg-9 col-xl-7">
                <div
                  className={`row no-gutters flex-column'} flex-sm-row align-items-sm-center`}
                >
                  <div className="col">
                    <h3 className="text-white ">If You Have More Questions</h3>
                    {/* <p className="cta-desc ">Quisque volutpat mattis eros</p> */}
                  </div>

                  <div className="col-auto">
                    <Link to={`/contact/`} className="btn btn-outline-white">
                      <span>CONTACT US</span>
                      <i className="icon-long-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default React.memo(FAQ);
